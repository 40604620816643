import React, {useState, useRef, useEffect, useCallback} from "react";
import ReactMapGL, {Source, Layer, LayerProps} from 'react-map-gl';
// import type {HeatmapLayer} from 'react-map-gl';
import bbox from '@turf/bbox';
import type {MapRef} from 'react-map-gl';
import mapboxgl from "mapbox-gl";
import {ACLEDData, ACLEDDataItem} from './../react-app-env';
import {MAPBOX_ACCESS_TOKEN, MAPBOX_STYLE} from './../constants';
import 'mapbox-gl/dist/mapbox-gl.css';

import {clusterLayer, clusterCountLayer, pointsLayer, heatmapLayer} from './MapLayers';

import LayerControl from './LayerControl';
import EventsPanel from "./EventsPanel";

// Fix for this issue: https://github.com/visgl/react-map-gl/issues/1266
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;


const Map: React.FC<{
  data: any,
  zoomResetCount: number,
  filteredDates: {start: Date, end: Date} | undefined,
}> = ({
  data,
  zoomResetCount,
  filteredDates,
}) => {
  // const mapRef = useRef<MapRef>();
  const mapRef = useRef<any>();
  const [selectedEvents, setSelectedEvents] = useState<ACLEDDataItem[]>([]);
  const [filteredData, setFilteredData] = useState<any>(data);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [mapLayer, setMapLayer] = useState('points');

  // Setup viewport
  const [viewport, setViewport] = useState({
    latitude: 54,
    longitude: 3,
    zoom: 4
  });

  // filter data based on selected dates
  useEffect(() => {
    if (data) {
      if (filteredDates) {
        const filteredData = data.features.filter((feature: ACLEDDataItem) => {
          if (filteredDates.start && filteredDates.end) {
            const date = new Date(feature.properties.event_date);
            return date >= filteredDates.start && date <= filteredDates.end;
          } else {
            return true;
          }
        });
        setFilteredData({
          type: 'FeatureCollection',
          features: filteredData,
        });
      } else {
        setFilteredData(data);
      }
    }  else {
      setFilteredData({
        type: 'FeatureCollection',
        features: [],
      });
    }
  }, [filteredDates, data]);

  // Set layer visibility
  useEffect(() => {
    const map = mapRef.current.getMap();
    if (loaded) {
      if(mapLayer === 'points') {
        map.setLayoutProperty(
          'heatmap',
          'visibility',
          'none'
        );
        map.setLayoutProperty(
          'point',
          'visibility',
          'visible'
        );
        map.setLayoutProperty(
          'clusters',
          'visibility',
          'visible'
        );
        map.setLayoutProperty(
          'cluster-count',
          'visibility',
          'visible'
        );
        
      } else {
        map.setLayoutProperty(
          'heatmap',
          'visibility',
          'visible'
        );
        map.setLayoutProperty(
          'point',
          'visibility',
          'none'
        );
        map.setLayoutProperty(
          'clusters',
          'visibility',
          'none'
        );
        map.setLayoutProperty(
          'cluster-count',
          'visibility',
          'none'
        );
      }
    }
  }, [mapLayer])

  // Zoom to bounds
  useEffect(() => {
    if (data !== undefined && data.features.length > 0) {
      const map = mapRef.current.getMap();
      const [minLng, minLat, maxLng, maxLat] = bbox(data);
      map.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat]
        ],
        {padding: 100, duration: 0}
      );

      // TODO: to get the animated zoom, we need to wait to update the viewport
      setViewport({
        latitude: map.getCenter().lat,
        longitude: map.getCenter().lng,
        zoom: map.getZoom(),
      });
    }
  }, [zoomResetCount]);

  const onclick = (event: any) => {
    // See if we've clicked on a cluster
    const features = mapRef.current.queryRenderedFeatures(event.point, {
      layers: ["clusters"],
    });

    if (features.length) {
      const clusterId = features[0].properties.cluster_id;
      const point_count = features[0].properties.point_count;
      const clusterSource = mapRef.current.getMap().getSource('clustered-data');

      clusterSource.getClusterLeaves(clusterId, point_count, 0, function(err: any, aFeatures: ACLEDDataItem[]){
        setSelectedEvents(aFeatures);
      })
    } else {
      // See if we've clicked on a point
      const features = mapRef.current.queryRenderedFeatures(event.point, {
        layers: ["point"],
      });
      if (features.length) setSelectedEvents([features[0]]);
    }
  }

  const onMouseEnter = useCallback(() => {mapRef.current.cursor = 'pointer'}, []);
  const onMouseLeave = useCallback(() => {mapRef.current.cursor = 'default'}, []);
  const onLoad = useCallback(() => {setLoaded(true)}, []);

  return (
    <>
      <ReactMapGL
        className="relative"
        ref={mapRef}
        {...viewport}
        onViewportChange={(nextViewport: any) => setViewport(nextViewport)}
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
        mapStyle={MAPBOX_STYLE}
        width="100%"
        height="100%"
        onClick={onclick}
        // onMouseMove={onHover}
        interactiveLayerIds={["point", "clusters", "cluster-count"]}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onLoad={onLoad}
      >
        <Source
          id="unclustered-data"
          cluster={false}
          type="geojson"
          data={filteredData}
        >
          <Layer {...heatmapLayer} key="heatmap-layer" />
        </Source>
        <Source
          id="clustered-data"
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={50}
          type="geojson"
          data={filteredData}
        >
          <Layer {...pointsLayer} key="points-layer" />
          <Layer {...clusterLayer} key="cluster-layer" />
          <Layer {...clusterCountLayer} key="cluster-count-layer" />
        </Source>
        
      </ReactMapGL>
      <LayerControl setLayer={setMapLayer} />
      {selectedEvents.length > 0 && 
        <div className="w-1/4 absolute h-screen top-0 right-0 overflow-y-scroll bg-brand-black text-brand-light-grey drop-shadow-md z-50 p-8 scrollbar-thin scrollbar-thumb-secondary scrollbar-track-brand-dark-grey">
          <EventsPanel
            events={selectedEvents}
            setSelectedEvents={setSelectedEvents}
          />
        </div>
      }
    </>
  );
}

export default Map;
