import React from "react";
import {ACLEDDataItem} from './../react-app-env';
import EventsPanelItem from './EventsPanelItem';

const EventsPanel: React.FC<{
  setSelectedEvents: (events: ACLEDDataItem[]) => void;
  events: ACLEDDataItem[];
}> = ({
  setSelectedEvents,
  events,
}) => {
  return (
    <div className="">
      <button onClick={() => setSelectedEvents([])}>Close</button>
      {events.map((event) => (
        <EventsPanelItem event={event} key={event.properties.data_id} />
      ))}
    </div>
  );
}

export default EventsPanel;
