import type {LayerProps} from 'react-map-gl';

export const pointsLayer: LayerProps = {
  id: 'point',
  type: 'circle',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-radius': 5,
    'circle-color': '#007cbf'
  },
};

export const clusterLayer: LayerProps = {
  id: 'clusters',
  type: 'circle',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
    'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
  },
  layout: {
    visibility: 'visible'
  }
};

export const clusterCountLayer: any = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'earthquakes',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
    visibility: 'visible'
  },
};

const HEATMAP_MAX_ZOOM_LEVEL = 10;
export const heatmapLayer: any = {
  id: 'heatmap',
  maxzoom: HEATMAP_MAX_ZOOM_LEVEL,
  type: 'heatmap',
  paint: {
    'heatmap-weight': ['interpolate', ['linear'], ['get', 'mag'], 0, 0, 6, 1],
    'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, HEATMAP_MAX_ZOOM_LEVEL, 1],
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(218,241,238,0)',
      0.2,
      'rgba(200,234,229,0.75)',
      0.4,
      'rgba(181,227,220,0.75)',
      0.6,
      'rgba(161,222,211,0.75)',
      0.8,
      'rgba(142,215,201,0.75)',
      0.9,
      'rgba(121,208,190,0.75)'
    ],
    'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, HEATMAP_MAX_ZOOM_LEVEL, 40],
    'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 9, 0]
  },
  layout: {
    visibility: 'none'
  }
};
