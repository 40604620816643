import React, {useState, useEffect} from "react";
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import BrushChart from "./Graph/BrushChart";
import {ACLEDData, ACLEDDataCount} from './../react-app-env';

const DataPanel: React.FC<{
  events: ACLEDData | undefined;
  updateFilteredDates: (selectedDates: {start: Date, end: Date}) => void;
}> = ({
  events,
  updateFilteredDates,
}) => {
  const [data, setData] = useState<ACLEDDataCount[]>([]);
  useEffect(() => {
    const counts: any = {}
    events?.features.forEach((event) => {
      if (counts[event.properties.event_date]) {
        counts[event.properties.event_date] += 1;
      } else {
        counts[event.properties.event_date] = 1;
      }
    })
    const data: ACLEDDataCount[] = [];
    for (const key in counts) {
      data.push({
        date: new Date(key),
        count: counts[key]
      })
    }
    setData(data);
  }, [events]);
  return (
    <>
      {data.length > 0 && 
        <ParentSize className="select-none">
          {({ width, height }) => <BrushChart
            width={width}
            height={height}
            data={data} 
            updateSelectedDates={updateFilteredDates}
          />}
        </ParentSize>
      }
    </>
  );
}

export default DataPanel;
