import React, {useState} from "react";

const SearchField: React.FC<{
  options: string[];
  updateMatches: (matches: string[]) => void;
}> = ({
  options,
  updateMatches,
}) => {
  const [value, setValue] = useState("");
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (e.target.value === "") {
      updateMatches(options);
    } else {
      updateMatches(options.filter((option) => option.toLowerCase().includes(e.target.value.toLowerCase())));
    }
  };
  return (
    <div className="text-brand-black">
      <input
        type="search"
        value={value}
        onChange={onChange}
        className="bg-brand-light-grey p-1 border border-secondary w-full"
        placeholder="Search"
      />
    </div>
  )
}

export default SearchField;
