import React from "react";

const LayerControl: React.FC<{
  setLayer: (layer: string) => void;
}> = ({setLayer}) => {
  const buttonClass = "bg-primary text-brand-black font-bold py-2 px-4 mr-2";
  return (
    <div className="absolute top-4 right-4">
      <button className={buttonClass} onClick={() => setLayer('points')}>Points</button>
      <button className={buttonClass} onClick={() => setLayer('heatmap')}>Heatmap</button>
    </div>
  );
}

export default LayerControl;
