import React, {useState} from "react";
import {ACLEDData, AvailableFilters, SelectedFilters, StructuredData} from './../react-app-env';
import FilterSelect from './FilterSelect';

const getFilteredIds = (key: string, data: StructuredData | undefined, filters: SelectedFilters | undefined): string[] => {
  if (data === undefined || filters === undefined) { return []; }

  let filteredIds: string[] = data.all;
  const dataCopy: any = {  // TODO: fix this
    eventTypes: data.eventTypes,
    subEventTypes: data.subEventTypes,
    actors: data.actors,
    assocActors: data.assocActors,
  };
  
  
  for (let x of Object.keys(dataCopy)) {
    if (x === key) continue;
    
    const categoryFilters = filters[x];
    if (categoryFilters) {
      const categoryIds: string[] = [];
      for (let filter of categoryFilters) {
        categoryIds.push(...dataCopy[x][filter]);
      }
      if (categoryIds.length > 0) {
        filteredIds = filteredIds.filter(id => categoryIds.includes(id));
      }
    }
  }

  return filteredIds
}

const Filters: React.FC<{
  data: ACLEDData | undefined;
  rawData: ACLEDData | undefined;
  availableFilters: AvailableFilters | undefined;
  selectedFilters: SelectedFilters | undefined;
  structuredData: StructuredData | undefined;
  setFilterSelections: (filterSelections: SelectedFilters) => void;
}> = ({
  data,
  rawData,
  availableFilters,
  selectedFilters,
  structuredData,
  setFilterSelections
}) => {
  const [filterDisplayed, setFilterDisplayed] = useState<string>('');

  if (data === undefined || rawData === undefined || availableFilters === undefined || selectedFilters === undefined) return <div />

  const updateFilterSelections = (filterSelections: string[], key: string) => {
    const newFilterSelections = {...selectedFilters};
    newFilterSelections[key] = filterSelections;
    setFilterSelections(newFilterSelections);
  }

  return (
    <>
      <FilterSelect
        label="Event Types"
        filterOptions={availableFilters.eventTypes || []}
        filterSelections={selectedFilters.eventTypes || []}
        updateFilterSelections={(filterSelections: string[]) => updateFilterSelections(filterSelections, 'eventTypes')}
        toggleDisplay={() => setFilterDisplayed(filterDisplayed === 'eventTypes' ? '' : 'eventTypes')}
        display={filterDisplayed === 'eventTypes'}
        optionToIds={structuredData?.eventTypes || {}}
        filteredIds={getFilteredIds('eventTypes', structuredData, selectedFilters)}
      />
      <FilterSelect
        label="Sub Event Types"
        filterOptions={availableFilters.subEventTypes || []}
        filterSelections={selectedFilters.subEventTypes || []}
        updateFilterSelections={(filterSelections: string[]) => updateFilterSelections(filterSelections, 'subEventTypes')}
        toggleDisplay={() => setFilterDisplayed(filterDisplayed === 'subEventTypes' ? '' : 'subEventTypes')}
        display={filterDisplayed === 'subEventTypes'}
        optionToIds={structuredData?.subEventTypes || {}}
        filteredIds={getFilteredIds('subEventTypes', structuredData, selectedFilters)}
      />
      <FilterSelect
        label="Actors"
        filterOptions={availableFilters.actors || []}
        filterSelections={selectedFilters.actors || []}
        updateFilterSelections={(filterSelections: string[]) => updateFilterSelections(filterSelections, 'actors')}
        toggleDisplay={() => setFilterDisplayed(filterDisplayed === 'actors' ? '' : 'actors')}
        display={filterDisplayed === 'actors'}
        optionToIds={structuredData?.actors || {}}
        filteredIds={getFilteredIds('actors', structuredData, selectedFilters)}
      />
      <FilterSelect
        label="Assoc. Actors"
        filterOptions={availableFilters.assocActors || []}
        filterSelections={selectedFilters.assocActors || []}
        updateFilterSelections={(filterSelections: string[]) => updateFilterSelections(filterSelections, 'assocActors')}
        toggleDisplay={() => setFilterDisplayed(filterDisplayed === 'assocActors' ? '' : 'assocActors')}
        display={filterDisplayed === 'assocActors'}
        optionToIds={structuredData?.assocActors || {}}
        filteredIds={getFilteredIds('assocActors', structuredData, selectedFilters)}
      />
    </>
  );
}

export default Filters;
