import React, {useState} from "react";
import {AvailableData, SelectedData} from "./../react-app-env";

import StyledCheckbox from "./widgets/StyledCheckbox";
import SearchField from './widgets/SearchField';

const DataSelector: React.FC<{
  availableDataSets: AvailableData[] | undefined;
  selectedDataSet: SelectedData;
  updateSelectedDataSet: (dataSet: SelectedData) => void;
  close: () => void;
}> = ({
  availableDataSets,
  selectedDataSet,
  updateSelectedDataSet,
  close,
}) => {
  const [iso3, setIso3] = useState<string[]>(selectedDataSet.iso3);
  const [label, setLabel] = useState<string[]>(selectedDataSet.label);
  const [startDate, setStartDate] = useState<string>(selectedDataSet.start_date || '');
  const [endDate, setEndDate] = useState<string>(selectedDataSet.end_date || '');
  const [searchMatches, setSearchMatches] = useState<string[]>(availableDataSets?.map((dataSet) => dataSet.label) || []);

  const handleChangeCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selection = event.target.value.split('|');

    if (event.target.checked) {
      setIso3((prev) => [...prev, selection[0]]);
      setLabel((prev) => [...prev, selection[1]]);
    } else {
      setIso3((prev) => prev.filter((iso) => iso !== selection[0]));
      setLabel((prev) => prev.filter((label) => label !== selection[1]));
    }
  };

  const handleChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value);
  };

  const handleChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
  };

  const updateMatches = (matches: string[]) => {
    setSearchMatches(matches);
  }

  const sortedDataSets = availableDataSets === undefined ? undefined : availableDataSets.sort((a, b) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    return 0;
  });

  const filteredDataSets = sortedDataSets?.filter((dataSet) => searchMatches.includes(dataSet.label)) || [];


  return (
    <div className="h-screen w-screen absolute top-0 left-0 flex items-center justify-center bg-brand-black/90 z-50">
      <div className="p-6 relative w-3/5 max-w-lg bg-brand-black text-brand-light-grey ">
        <button className="absolute -top-5 -right-3 text-4xl font-black text-primary" onClick={close}>X</button>

        <div className="mb-12">
          <div className="mb-2 uppercase text-xl">Countries</div>
          <div className="mb-4">
            <SearchField
              options={availableDataSets?.map((dataSet) => dataSet.label) || []}
              updateMatches={updateMatches}
            />
          </div>
          <div className="flex flex-wrap max-h-96 overflow-y-scroll relative scrollbar-thin scrollbar-thumb-secondary scrollbar-track-brand-dark-grey">
            {filteredDataSets?.map((dataSet) => (
              <div className="basis-1/3 flex-1 grow pb-1 px-1" key={`${dataSet.label} ${dataSet.iso3}`}>
                <StyledCheckbox
                  checked={iso3.includes(dataSet.iso3)}
                  value={`${dataSet.iso3}|${dataSet.label}`}
                  id={`checkbox-id-${dataSet.iso3}`}
                  label={dataSet.label}
                  onChange={handleChangeCountry}
                  key={`checkbox-id-${dataSet.iso3}`}
                />
              </div>
            ) || [])}

          </div>
        </div>
        <div className="mb-8">
          <div className="mb-2 uppercase text-xl">Date range</div>
          <div className="flex justify-between items-center mb-2">
            <label htmlFor="dataset-start-date">Start Date</label>
            <div className="text-brand-black w-3/4">
              <input
                type="date"
                id="dataset-start-date"
                value={startDate}
                onChange={handleChangeStartDate}
                pattern="\d{4}-\d{2}-\d{2}"
                className="bg-brand-light-grey p-1 border border-secondary w-full"
              />
            </div>
          </div>
          <div className="flex justify-between items-center">
            <label htmlFor="dataset-end-date">End Date</label>
            <div className="text-brand-black w-3/4">
              <input
                type="date"
                id="dataset-end-date"
                value={endDate}
                onChange={handleChangeEndDate}
                pattern="\d{4}-\d{2}-\d{2}"
                className="bg-brand-light-grey p-1 border border-secondary w-full"
              />
            </div>
            
          </div>
        </div>
        <div className="flex justify-evenly">
          <button
            className="border-primary border bg-primary text-brand-black font-bold py-2 px-4"
            onClick={() => updateSelectedDataSet({
              label,
              iso3,
              start_date: startDate,
              end_date: endDate
            })}
          >
            Load data
          </button>
          <button
            className="border-primary border text-primary font-bold py-2 px-4"
            onClick={close}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
export default DataSelector;
