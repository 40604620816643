import React from "react";

const StyledCheckbox: React.FC<{
  checked: boolean;
  value: string;
  id: string;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}> = ({
  checked,
  value,
  id,
  label,
  onChange,
  disabled = false,
}) => {
  return (
    <>
      <input
        type="checkbox"
        checked={checked}
        value={value}
        onChange={onChange}
        id={id}
        className="opacity-0 cursor-pointer absolute"
        disabled={disabled}
      />
      
      <label className="cursor-pointer flex mb-2" htmlFor={id}>
        <div className={`border-secondary border bg-secondary font-bold w-6 h-6 rounded flex justify-center align-middle`}>
          <div style={{'lineHeight': '1.5em'}}>{checked ? '✓' : ''}</div>
        </div>
        <div className={`ml-2 flex-1 ${disabled && 'text-gray-600'}`}>{label}</div>
      </label>
    </>
  )
}

export default StyledCheckbox;
