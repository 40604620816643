import React from 'react';
import { Group } from '@visx/group';
import { AreaClosed } from '@visx/shape';
import { AxisLeft, AxisBottom, AxisScale } from '@visx/axis';
import { curveMonotoneX } from '@visx/curve';

import {ACLEDDataCount} from './../../react-app-env';

// Initialize some variables
const axisColor = '#C8C7C9';
const axisBottomTickLabelProps = {
  textAnchor: 'middle' as const,
  fontSize: 10,
  fill: axisColor,
};
const axisLeftTickLabelProps = {
  dx: '-0.25em',
  dy: '0.25em',
  fontSize: 10,
  textAnchor: 'end' as const,
  fill: axisColor,
};

// accessors
const getDate = (d: ACLEDDataCount) => d.date;
const getCount = (d: ACLEDDataCount) => d.count;

export default function AreaChart({
  data,
  chartColor,
  width,
  yMax,
  margin,
  xScale,
  yScale,
  hideBottomAxis = false,
  hideLeftAxis = false,
  top,
  left,
  children,
}: {
  data: ACLEDDataCount[];
  chartColor: string;
  xScale: AxisScale<number>;
  yScale: AxisScale<number>;
  width: number;
  yMax: number;
  margin: { top: number; right: number; bottom: number; left: number };
  hideBottomAxis?: boolean;
  hideLeftAxis?: boolean;
  top?: number;
  left?: number;
  children?: React.ReactNode;
}) {
  if (width < 10) return null;
  return (
    <Group left={left || margin.left} top={top || margin.top}>
      <AreaClosed<ACLEDDataCount>
        data={data}
        x={(d) => xScale(getDate(d)) || 0}
        y={(d) => yScale(getCount(d)) || 0}
        yScale={yScale}
        strokeWidth={1}
        stroke={chartColor}
        fill={chartColor}
        curve={curveMonotoneX}
      />
      {!hideBottomAxis && (
        <AxisBottom
          top={yMax}
          scale={xScale}
          numTicks={width > 520 ? 10 : 5}
          stroke={axisColor}
          tickStroke={axisColor}
          tickLabelProps={() => axisBottomTickLabelProps}
        />
      )}
      {!hideLeftAxis && (
        <AxisLeft
          scale={yScale}
          numTicks={5}
          stroke={axisColor}
          tickStroke={axisColor}
          tickLabelProps={() => axisLeftTickLabelProps}
          hideZero={true}
        />
      )}
      {children}
    </Group>
  );
}
