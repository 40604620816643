import {ACLEDData, AvailableFilters, StructuredData} from './../react-app-env';

export const prepareFilters = (data: ACLEDData) => {
  // Parse the data to get the available filter options
  const availableFilters: AvailableFilters = {
    startDate: null,
    endDate: null,
    eventTypes: [],
    subEventTypes: [],
    actors: [],
    assocActors: [],
    maxFatalities: 0,
    minFatalities: 0,
  }
  const structuredData: StructuredData = {
    all: [],
    dates: {},
    eventTypes: {},
    subEventTypes: {},
    actors: {},
    assocActors: {},
    fatalities: {},
  }
  for (let feature of data.features) {
    const properties = feature.properties;
    const startDate = new Date(properties.event_date);
    const endDate = new Date(properties.event_date);
    structuredData.all.push(properties.data_id);

    if (startDate.toLocaleDateString() in structuredData.dates) {
      structuredData.dates[startDate.toLocaleDateString()].push(properties.data_id);
    } else {
      structuredData.dates[startDate.toLocaleDateString()] = [properties.data_id];
    }

    if (availableFilters.startDate === null || startDate < availableFilters.startDate) {
      availableFilters.startDate = startDate;
    }
    if (availableFilters.endDate === null || endDate > availableFilters.endDate) {
      availableFilters.endDate = endDate;
    }

    if (properties.fatalities in structuredData.fatalities) {
      structuredData.fatalities[properties.fatalities].push(properties.data_id);
    } else {
      structuredData.fatalities[properties.fatalities] = [properties.data_id];
    }
    availableFilters.maxFatalities = properties.fatalities > availableFilters.maxFatalities ? properties.fatalities : availableFilters.maxFatalities;
    availableFilters.minFatalities = properties.fatalities < availableFilters.minFatalities ? properties.fatalities : availableFilters.minFatalities;
    
    for (let e of properties.event_type) {
      if (e in structuredData.eventTypes) {
        structuredData.eventTypes[e].push(properties.data_id);
      } else {
        structuredData.eventTypes[e] = [properties.data_id];
      }

      if (availableFilters.eventTypes === null) {
        availableFilters.eventTypes = [e];
      }
      else if (!availableFilters.eventTypes.includes(e)) {
        availableFilters.eventTypes.push(e);
      }
    }

    for (let e of properties.sub_event_type) {
      if (e in structuredData.subEventTypes) {
        structuredData.subEventTypes[e].push(properties.data_id);
      } else {
        structuredData.subEventTypes[e] = [properties.data_id];
      }
      if (availableFilters.subEventTypes === null) {
        availableFilters.subEventTypes = [e];
      }
      else if (!availableFilters.subEventTypes.includes(e)) {
        availableFilters.subEventTypes.push(e);
      }
    }

    if (availableFilters.actors === null) { availableFilters.actors = [] };
    for (let a of properties.actor1) {
      if (a in structuredData.actors) {
        structuredData.actors[a].push(properties.data_id);
      } else {
        structuredData.actors[a] = [properties.data_id];
      }
      if (a !== "" && !availableFilters.actors.includes(a)) {
        availableFilters.actors.push(a);
      }
    }

    for (let a of properties.actor2) {
      if (a in structuredData.actors) {
        structuredData.actors[a].push(properties.data_id);
      } else {
        structuredData.actors[a] = [properties.data_id];
      }      
      if (a !== "" && !availableFilters.actors.includes(a)) {
        availableFilters.actors.push(a);
      }
    }

    if (availableFilters.assocActors === null) { availableFilters.assocActors = [] };

    for (let el of properties.assoc_actor_1) {
      if (!availableFilters.assocActors.includes(el)) {
        if (el in structuredData.assocActors) {
          structuredData.assocActors[el].push(properties.data_id);
        } else {
          structuredData.assocActors[el] = [properties.data_id];
        }
        availableFilters.assocActors.push(el);
      }
    }
    for (let el of properties.assoc_actor_2) {
      if (!availableFilters.assocActors.includes(el)) {
        if (el in structuredData.assocActors) {
          structuredData.assocActors[el].push(properties.data_id);
        } else {
          structuredData.assocActors[el] = [properties.data_id];
        }
        availableFilters.assocActors.push(el);
      }
    }
  }
  return {availableFilters, structuredData};
}

export const filter_filters = (filters: AvailableFilters, structuredData: StructuredData) => {
  // Restrict the available filters based on what has been selected in other categories.
  // For example if the user has selected something from the event type category, only
  // show filters in the sub event type category that will display > 0 results.
}
