import React from "react";
import {ACLEDDataItem} from './../react-app-env';

const EventsPanelItem: React.FC<{
  event: ACLEDDataItem;
}> = ({event}) => {
  return (
    <div className="mb-8">
      {event.properties.notes}
    </div>
  );
}

export default EventsPanelItem;
