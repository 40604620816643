import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import SearchField from "./widgets/SearchField";


import StyledCheckbox from "./widgets/StyledCheckbox";

const FilterSelect: React.FC<{
  filterOptions: string[];
  filterSelections: string[];
  label: string;
  updateFilterSelections: (filterSelections: string[]) => void;
  toggleDisplay: () => void;
  display: boolean;
  optionToIds: {[key: string]: string[]};
  filteredIds: string[];
}> = ({
  filterOptions,
  filterSelections,
  label,
  updateFilterSelections,
  toggleDisplay,
  display,
  optionToIds,
  filteredIds,
}) => {
  const [searchMatches, setSearchMatches] = useState<string[]>(filterOptions);

  const updateMatches = (matches: string[]) => {
    setSearchMatches(matches);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilterSelections = [...filterSelections];
    if (newFilterSelections.includes(event.target.value)) {
      newFilterSelections.splice(newFilterSelections.indexOf(event.target.value), 1);
    } else {
      newFilterSelections.push(event.target.value);
    }
    updateFilterSelections(newFilterSelections);
  };

  const availableFilters = [];
  const unavailableFilters = [];
  for (let filterOption of filterOptions.sort()) {
    const checked = filterSelections.includes(filterOption);
    if (searchMatches.includes(filterOption) && filterOption !== '') {
      const availableIds = optionToIds[filterOption].filter(id => filteredIds.includes(id));
      const filter = <div className="flex items-baseline pb-1">
          <StyledCheckbox
            checked={checked}
            value={filterOption}
            id={filterOption.replace(/[\W_]+/g," ")}
            label={`${filterOption} (${availableIds.length})`}
            onChange={handleChange}
            disabled={availableIds.length === 0}
            key={filterOption}
          />
        </div>

      if (availableIds.length > 0) {
        availableFilters.push(filter);
      } else {
        unavailableFilters.push(filter);
      };
    }
  }

  return (
    <div className="mb-4">
      <div className="mb-2 font-bold text-lg px-8">
        <button onClick={toggleDisplay}>
          <span className="pr-2">
            {display ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleRight} />}
          </span>
          {label}
        </button>        
      </div>
      <div className={`${display ? '' : 'hidden'} pl-8`}>
        <div className="mb-4 pr-8">
          <SearchField
            options={filterOptions}
            updateMatches={updateMatches}
          />
        </div>
        <div className="overflow-y-scroll max-h-64 relative scrollbar-thin scrollbar-thumb-secondary scrollbar-track-brand-dark-grey">
          {availableFilters}
          {unavailableFilters}
        </div>
      </div>
    </div>
  );
}

export default FilterSelect;
