import React, {useState, useEffect} from 'react';

import {AvailableData, SelectedData, ACLEDData, SelectedFilters, AvailableFilters, StructuredData} from './react-app-env';
import {API_URL} from './constants';
import {prepareFilters} from './util/filter_utils';
import Map from './components/Map';
import DataPanel from './components/DataPanel';
import Filters from './components/Filters';
import DataSelector from './components/DataSelector';

import './App.css';
import Loading from './components/Loading';

const App: React.FC = () => {
  const [availableFilters, setAvailableFilters] = useState<AvailableFilters>();
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>();
  const [rawData, setRawData] = useState<ACLEDData>();
  const [data, setData] = useState<ACLEDData>();
  const [structuredData, setStructuredData] = useState<StructuredData>();
  const [availableDataSets, setAvailableDataSets] = useState<AvailableData[]>();
  const [selectedDataSet, setSelectedDataSet] = useState<SelectedData>({label: [], iso3: [], start_date: '', end_date: ''});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showDataSelector, setShowDataSelector] = useState<boolean>(false);
  const [zoomResetCount, setZoomResetCount] = useState<number>(0);
  const [filteredDates, setFilteredDates] = useState<{start: Date, end: Date}>();

  const buildFilters = (data: ACLEDData) => {
    const {availableFilters, structuredData } = prepareFilters(data);
    
    setAvailableFilters(availableFilters);
    setSelectedFilters({
      startDate: null,
      endDate: null,
      eventTypes: null,
      subEventTypes: null,
      actors: null,
      assocActors: null,
      maxFatalities: null,
      minFatalities: null,
    });
    setStructuredData(structuredData);
  }

  // Load data from the server
  useEffect(() => {
    if (selectedDataSet && selectedDataSet.iso3.length > 0) {
      setIsLoading(true);
      fetch(`${API_URL}/events/?iso3=${selectedDataSet.iso3.join(',')}&start_date=${selectedDataSet.start_date}&end_date=${selectedDataSet.end_date}`)
        .then(res => res.json())
        .then((data: ACLEDData) => {
          setRawData(data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  }, [selectedDataSet]);

  // Load available data sets from the server
  useEffect(() => {
    fetch(`${API_URL}/datasets/`)
      .then(res => res.json())
      .then((data: AvailableData[]) => {
        setAvailableDataSets(data);
        setIsLoading(false);
        setShowDataSelector(true);
      });
  }, []);

  // Build filters and data when data is loaded
  useEffect(() => {
    setData(rawData);
    if (rawData !== undefined) {
      buildFilters(rawData);
      setIsLoading(false);
      setShowDataSelector(false);
      setZoomResetCount(z => z + 1);
    }
  }, [rawData]);

  // Update data when filters are updated
  useEffect(() => {
    const isStringArray = (el: string[] | number | Date | null): el is string[] => {
        if (
          el === null ||
          typeof el === "number" || 
          el instanceof Date
        ) { return false; } 
        return true;
    }

    const filterStringArray = (filters: string[], idArray: string[], data: { [key: string]: string[] }): string[] => {
      const matchedIDs: string[] = [];
      for (let filter of filters) {
        matchedIDs.push(...data[filter]);
      }
      return idArray.filter(id => matchedIDs.includes(id));
    }

    if (selectedFilters !== undefined && structuredData !== undefined && rawData !== undefined) {
      const newData = {...rawData};
      let includedIDs: string[] = [...structuredData.all];

      // Filter by event type
      const eventTypes = selectedFilters.eventTypes;
      if (isStringArray(eventTypes) && eventTypes.length > 0) {
        includedIDs = filterStringArray(eventTypes, includedIDs, structuredData.eventTypes);
      }

      // Filter by sub event type
      const subEventTypes = selectedFilters.subEventTypes;
      if (isStringArray(subEventTypes) && subEventTypes.length > 0) {
        includedIDs = filterStringArray(subEventTypes, includedIDs, structuredData.subEventTypes);
      }

      // Filter by actor
      const actors = selectedFilters.actors;
      if (isStringArray(actors) && actors.length > 0) {
        includedIDs = filterStringArray(actors, includedIDs, structuredData.actors);
      }

      // Filter by assoc actor
      const assocActors = selectedFilters.assocActors;
      if (isStringArray(assocActors) && assocActors.length > 0) {
        includedIDs = filterStringArray(assocActors, includedIDs, structuredData.assocActors);
      }

      // Filter the data to include only the filtered IDs
      if (includedIDs.length < structuredData.all.length) {
        newData.features = newData.features.filter(feature => includedIDs.includes(feature.properties.data_id));
      }
      setData(newData);
    }
  }, [selectedFilters, rawData, structuredData]);

  const updateSelectedFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
  }

  const updateSelectedDataSet = (dataSet: SelectedData) => {
    setSelectedDataSet(dataSet);
  }

  const updateFilteredDates = (dates: {start: Date, end: Date}) => {
    setFilteredDates(dates);
  }

  const clearFilters = () => {
    setSelectedFilters({
      startDate: null,
      endDate: null,
      eventTypes: null,
      subEventTypes: null,
      actors: null,
      assocActors: null,
      maxFatalities: null,
      minFatalities: null,
    });
  }

  return (
    <div>
      <div className="flex h-screen">
        <div className="w-1/4 h-full bg-brand-black text-brand-light-grey py-8	drop-shadow-md">
          <div className="mb-8 px-8">
            <div className="flex items-end justify-between mb-4">
              <div className="uppercase text-2xl">Data</div>
              <button
                className="text-primary font-bold"
                onClick={() => setShowDataSelector(true)}
              >
                change data
              </button>
            </div>
            
            <div>
              <div className="font-bold">Countries:</div>
              <div>{selectedDataSet.label.join('; ')} </div>
              <div className="font-bold">Date range:</div>
              <div>{selectedDataSet.start_date} to {selectedDataSet.end_date}</div>
            </div>
            <div>
              
            </div>
          </div>
          
          
          <div className="flex items-end justify-between mb-4 px-8">
            <div className="uppercase text-2xl">Filters</div>
            <button
              className="text-primary font-bold"
              onClick={clearFilters}
            >
              clear filters
            </button>
          </div>
          <Filters
            data={data}
            rawData={rawData}
            availableFilters={availableFilters}
            selectedFilters={selectedFilters}
            setFilterSelections={updateSelectedFilters}
            structuredData={structuredData}
          />
        </div>
        <div className="flex flex-col w-3/4 h-full">
          <Map
            data={data}
            zoomResetCount={zoomResetCount}
            filteredDates={filteredDates}
          />
          <div className='h-1/4 bg-brand-black'>
            <DataPanel 
              events={data}
              updateFilteredDates={updateFilteredDates}
            />
          </div>
        </div>
      </div>
      {showDataSelector && 
        <DataSelector
          availableDataSets={availableDataSets}
          selectedDataSet={selectedDataSet}
          updateSelectedDataSet={updateSelectedDataSet}
          close={() => setShowDataSelector(false)}
        />
      }
      {isLoading && <Loading />}
    </div>
  );
}

export default App;
