import React from "react";

const Loading: React.FC = () => {
  return (
    <div
      className="h-screen w-screen absolute top-0 left-0 flex items-center justify-center bg-brand-black/90 z-50"
    >
      <div
        className="uppercase text-6xl text-brand-light-grey"
      >
        Loading...
      </div>
    </div>
  );
}

export default Loading;
